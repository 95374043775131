import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import showErrorSnackBar from '@core/utils/errorSnackBar';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ErrorHandlingService {
	mutateLoading = false;

	loadingSubject = new BehaviorSubject<boolean>(false);

	loading$ = this.loadingSubject.asObservable();

	constructor(
		private _authService: AuthService,
		private _snackBar: MatSnackBar,
		private _router: Router
	) {}

	// eslint-disable-next-line
	handleHttpError(response, req) {
		if (response instanceof HttpErrorResponse) {
			if (response.status === 400) {
				showErrorSnackBar(
					this._snackBar,
					response.error.title || response.error.message
				);
			}

			if (response.status === 422) {
				showErrorSnackBar(
					this._snackBar,
					response.error.title || response.error.message
				);
			}

			if (response.status === 401) {
				this._authService.logout(this._router.routerState.snapshot.url);

				return of(null);
			} else if (response.status === 429) {
				showErrorSnackBar(
					this._snackBar,
					response.error.title || response.error.message
				);
			} else if (response.status === 404) {
				this._router.navigate(['/code/404'], {
					state: {
						requestedUrl: req.url
					}
				});
				showErrorSnackBar(
					this._snackBar,
					response.error.title || response.error.message
				);
			} else {
				showErrorSnackBar(
					this._snackBar,
					response.error.title || response.error.message
				);
			}
			this.mutateLoading = false;

			return throwError(response);
		}
	}
}
