import { Injectable } from '@angular/core';
import {
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '@environment/environment';
import { AuthService } from '@core/services/auth.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandlingService } from '../services/error.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
	constructor(
		private _authService: AuthService,
		private _errorHandlingService: ErrorHandlingService
	) {}

	intercept(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		req: HttpRequest<any>,
		next: HttpHandler
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Observable<any> {
		const headers: { [key: string]: string } = {};
		if (!req.url.includes('admin/') && !req.url.includes('player/')) {
			return next.handle(req);
		}

		headers['Cache-Control'] = 'no-cache, no-store';

		// Add the api url to every request
		req = req.clone({
			url: `${environment.baseApiUrl}/${environment.apiVersion}/${req.url}`,
			setHeaders: headers
		});

		const token = this._authService.token;

		// Add the auth token
		if (token) {
			req = this._addToken(req, token);
		}

		return next.handle(req).pipe(
			catchError((response) => {
				return this._errorHandlingService.handleHttpError(response, req);
			})
		);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private _addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({
			setHeaders: { token }
		});
	}
}
