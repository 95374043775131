import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Permission } from '@core/models/entities/Permission';
import { User } from '@core/models/entities/User';
import { PERMISSIONS } from '@core/models/enums/permissions.enum';
import { ChangePasswordData } from '@shared/components/forms/change-password-form/change-password-form.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

interface LoginResponse {
	token: string;
	user: User;
}
interface Bootstrap {
	assetsUrl: string;
}

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	assetsUrl: string;

	user$ = new BehaviorSubject<User>(null);

	hasOperator = false;

	isPlayerAffiliate = false;

	isCasinoAffiliate = false;

	private _user: User;

	private _userPermissions: Permission[];

	get isAuthenticated(): boolean {
		try {
			return !this._jwtHelper.isTokenExpired(this.token);
		} catch (error) {
			return false;
		}
	}

	set userPermissions(permissions: Permission[]) {
		this._userPermissions = permissions;
	}

	get userPermissions(): Permission[] {
		return this._userPermissions;
	}

	set user(user: User) {
		this._user = user;
		this.user$.next(user);
	}

	get user(): User {
		return this._user;
	}

	get token(): string {
		return localStorage.getItem('token');
	}

	set token(token: string) {
		localStorage.setItem('token', token);
	}

	constructor(
		private _http: HttpClient,
		private _router: Router,
		private _jwtHelper: JwtHelperService
	) {}

	hasPermission(permission: PERMISSIONS): boolean {
		if (this.user?.affiliatesPlayer) return false;
		return !!this.userPermissions?.find((p) => p.name === permission);
	}

	login(body: { email: string; password: string }): Observable<LoginResponse> {
		return this._http.post<LoginResponse>('admin/auth/sign-in', body);
	}

	me(): Observable<User> {
		const subject = new Subject<User>();

		this._http.get<User>('admin/auth/me').subscribe((user: User) => {
			this.hasOperator = !!user.operator;

			this.isPlayerAffiliate = !!user.affiliatesPlayer;

			this.isCasinoAffiliate = !!user.affiliatesCasino;

			subject.next(user);
		});

		return subject;
	}

	editProfile(data: { name: string }): Observable<User> {
		return this._http.post<User>('admin/auth/edit-profile', data);
	}

	changePassword(data: ChangePasswordData): Observable<Record<string, never>> {
		return this._http.post<Record<string, never>>(
			'admin/auth/change-password',
			data
		);
	}

	logout(returnUrl?: string): Promise<boolean> {
		localStorage.removeItem('token');
		localStorage.removeItem('operator');
		this.user = null;

		const extras = {
			queryParams: {}
		} as NavigationExtras;

		if (returnUrl) {
			extras.queryParams.returnUrl = returnUrl;
		}

		return this._router.navigate(['auth/login'], extras);
	}

	requestPassword(email: string): Observable<Record<string, never>> {
		return this._http.post<Record<string, never>>(
			'admin/auth/request-password',
			{ email }
		);
	}

	resetPassword(data: ChangePasswordData): Observable<Record<string, never>> {
		return this._http.post<Record<string, never>>(
			'admin/auth/reset-password',
			data
		);
	}

	resendActivationEmail(email: string): Observable<string> {
		return this._http.post<string>('admin/auth/resend-activation-email', {
			email
		});
	}

	activateUser(
		token: string,
		password: string
	): Observable<Record<string, never>> {
		return this._http.post<Record<string, never>>('admin/auth/activate-user', {
			token,
			password
		});
	}

	appInitialize(): Promise<boolean> {
		return new Promise((resolve) => {
			this._http.get('admin/bootstrap').subscribe((resp: Bootstrap) => {
				this.assetsUrl = resp.assetsUrl;
			});
			resolve(true);
		});
	}
}
