import { Component } from '@angular/core';

@Component({
	selector: 'soc-footer',
	styleUrls: ['./footer.component.scss'],
	templateUrl: './footer.component.html'
})
export class FooterComponent {
	year = new Date().getFullYear();
}
