import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { environment } from '@environment/environment';

@Component({
	selector: 'soc-app',
	templateUrl: './app.component.html'
})
export class AppComponent {
	constructor(iconsLibrary: NbIconLibraries) {
		document.getElementsByTagName('body')[0].id = environment.env;
		iconsLibrary.registerFontPack('solid', {
			packClass: 'fas',
			iconClassPrefix: 'fa'
		});
		iconsLibrary.setDefaultPack('solid');
	}
}
