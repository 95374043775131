<span class="created-by">© 2020 - {{year}} Socino</span>
<div class="socials">
	<a href="#" target="_blank" class="ion ion-social-github"></a>
	<a href="#" target="_blank" class="ion ion-social-facebook"></a>
	<a href="#" target="_blank" class="ion ion-social-twitter"></a>
	<a href="#" target="_blank" class="ion ion-social-linkedin"></a>
</div>
<div class="version">
	<p>v 1.4.6</p>
</div>
