<div class="header-container">
	<div class="logo-container">
		<a
			href="#"
			class="sidebar-toggle"
			matRipple
			[matRippleUnbounded]="true"
			[matRippleCentered]="true"
			(click)="toggleSidebar()"
		>
			<nb-icon icon="bars" pack="solid"></nb-icon>
		</a>
		<img
			class="logo"
			alt="Logo image"
			width="162"
			src="/assets/img/logo/socino-logo.png"
			(click)="navigateHome()"
		/>
	</div>
	<mat-slide-toggle
		[checked]="currentTheme === MaterialTheme.DARK"
		(change)="toggleTheme()"
	>
		{{currentTheme === MaterialTheme.DARK ? 'Light' : 'Dark'}}
	</mat-slide-toggle>
</div>

<div class="header-container">
	<nb-actions size="small">
		<nb-action
			class="user-action"
			matRipple
			[matRippleUnbounded]="true"
			[matRippleCentered]="true"
		>
			<nb-user
				[nbContextMenu]="userMenu"
				[nbContextMenuTag]="userMenuTag"
				[onlyPicture]="userPictureOnly"
				[name]="user?.name"
				[picture]="user?.image? user?.image : assetsUrl + 'static/user/userDefault.png'"
			>
			</nb-user>
		</nb-action>
	</nb-actions>
</div>
