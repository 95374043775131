import { Component, OnInit } from '@angular/core';
import {
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService
} from '@nebular/theme';

import { filter, map } from 'rxjs/operators';
import { User } from '@core/models/entities/User';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { LayoutService } from '@core/utils/layout.service';
import { RippleService } from '@core/utils/ripple.service';

enum MaterialTheme {
	'DARK' = 'Dark',
	'LIGHT' = 'Light'
}

@Component({
	selector: 'soc-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
	readonly assetsUrl = this._authService.assetsUrl;

	userPictureOnly = false;

	userMenuTag = 'user-menu';

	userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

	private _currentTheme: MaterialTheme;

	get user(): User {
		return this._authService.user;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	get MaterialTheme(): typeof MaterialTheme {
		return MaterialTheme;
	}

	get currentTheme(): MaterialTheme {
		return this._currentTheme;
	}

	set currentTheme(theme: MaterialTheme) {
		this._currentTheme = theme;
		this.userPreferedTheme = theme;

		if (theme === MaterialTheme.LIGHT) {
			this._themeService.changeTheme('material-light');
		} else {
			this._themeService.changeTheme('material-dark');
		}
	}

	set userPreferedTheme(theme: MaterialTheme) {
		localStorage.setItem('preferredTheme', theme);
	}

	get userPreferedTheme(): MaterialTheme {
		return localStorage.getItem('preferredTheme') as MaterialTheme;
	}

	constructor(
		private _sidebarService: NbSidebarService,
		private _menuService: NbMenuService,
		private _themeService: NbThemeService,
		private _layoutService: LayoutService,
		private _breakpointService: NbMediaBreakpointsService,
		private _rippleService: RippleService,
		private _nbMenuService: NbMenuService,
		private _router: Router,
		private _authService: AuthService
	) {
		// Set the default theme
		if (this.userPreferedTheme) {
			this.currentTheme = this.userPreferedTheme;
		} else {
			this.currentTheme = MaterialTheme.LIGHT;
		}

		this._rippleService.toggle(true);
	}

	ngOnInit(): void {
		const { xl } = this._breakpointService.getBreakpointsMap();

		this._themeService
			.onMediaQueryChange()
			.pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl))
			.subscribe(
				(isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
			);

		this._nbMenuService
			.onItemClick()
			.pipe(
				filter(({ tag }) => tag === this.userMenuTag),
				map(({ item: { title } }) => title)
			)
			.subscribe((title) => {
				switch (title) {
					case 'Profile':
						this._router.navigate(['profile']);
						break;
					case 'Log out':
						this._authService.logout();
						break;
				}
			});
	}

	toggleTheme(): void {
		this.currentTheme === MaterialTheme.DARK
			? (this.currentTheme = MaterialTheme.LIGHT)
			: (this.currentTheme = MaterialTheme.DARK);
	}

	toggleSidebar(): boolean {
		this._sidebarService.toggle(true, 'menu-sidebar');
		this._layoutService.changeLayoutSize();

		return false;
	}

	navigateHome(): boolean {
		this._menuService.navigateHome();
		return false;
	}
}
