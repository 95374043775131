import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

export const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthModule)
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/pages.module').then((m) => m.PagesModule)
	},
	{ path: '**', redirectTo: 'pages' }
];

const config: ExtraOptions = {
	useHash: false
};

@NgModule({
	imports: [RouterModule.forRoot(routes, config)],
	exports: [RouterModule]
})
export class AppRoutingModule {
	constructor(authService: AuthService) {
		if (authService.isAuthenticated) {
			authService.me().subscribe((user) => (authService.user = user));
		}
	}
}
