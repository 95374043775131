import {
	ModuleWithProviders,
	NgModule,
	Optional,
	SkipSelf
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { RippleService } from './utils/ripple.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIInterceptor } from './interceptors/api.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { LayoutService } from './utils/layout.service';
import {
	MatSnackBarModule,
	MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';

const DATA_SERVICES = [
	{ provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: RippleService }
];

const INTERCEPTORS = [
	{ provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true }
];

export const NB_CORE_PROVIDERS = [
	...DATA_SERVICES,
	...INTERCEPTORS,
	LayoutService
];

const jwtTokenGetter = () => {
	return localStorage.getItem('token');
};

@NgModule({
	imports: [
		CommonModule,
		MatSnackBarModule,
		JwtModule.forRoot({
			config: {
				tokenGetter: jwtTokenGetter
			}
		})
	],
	exports: [],
	providers: [
		{ provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 6000 } }
	]
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [...NB_CORE_PROVIDERS]
		};
	}
}
