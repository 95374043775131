import { Component } from '@angular/core';
import {
	NbMediaBreakpointsService,
	NbMenuService,
	NbSidebarService,
	NbThemeService
} from '@nebular/theme';

@Component({
	selector: 'soc-one-column-layout',
	styleUrls: ['./one-column.layout.scss'],
	templateUrl: './one-column.layout.html'
})
export class OneColumnLayoutComponent {
	// private _sub$: Subscription;

	constructor(
		private _sidebarService: NbSidebarService,
		private _menuService: NbMenuService,
		private _themeService: NbThemeService,
		private _breakpointService: NbMediaBreakpointsService
	) {
		// const { md } = this._breakpointService.getBreakpointsMap();
		// Whenever we're below the md breakpoint close the sidemenu whenever a menu item is clicked
		// this._sub$ = this._themeService
		// 	.onMediaQueryChange()
		// 	.pipe(
		// 		map(([, currentBreakpoint]) => currentBreakpoint.width <= md),
		// 		concatMap((isMd) => (isMd ? this._menuService.onItemClick() : of(null)))
		// 	)
		// 	.subscribe((item) => {
		// 		if (item && item.tag === 'sidebar-menu') {
		// 			this._sidebarService.collapse('menu-sidebar');
		// 		}
		// 	});
	}

	collapse(): void {
		if (window.innerWidth <= 1200) {
			this._sidebarService.compact('menu-sidebar');
		}
		if (window.innerWidth <= 576) {
			this._sidebarService.collapse('menu-sidebar');
		}
	}
}
